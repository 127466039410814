.banner{
    width: 100%;
    height: 2.3rem;
    background-color: var(--primary-orange) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: -2.3rem;
    transition: top 0.3s;
    z-index: 1000;
    /* border-radius: 10em; */
}

.banner.show {
    top: 0;
}

.link-text{
    color: white;  
    text-decoration: none;
    font-size: 1.1rem;
}

.link-text:hover{
    color: white;
}
