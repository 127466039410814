.testimonials {
  max-width: 100vw;
  background: #fff7f4;
  /* margin: 2em auto; */
  /* margin-bottom: 100px; */
  /* width: calc(100vw - 60px); */
  padding: 0 3em 3em 3em;
}

.eventHeading {
  font-weight: bold;
  font-size: 3em;
  text-align: center;
  padding: 1em 0 0 0;
  margin: -0.35em 0 1em 0em;
}

.wrapper {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 1em 1em 0 1em;
  margin: 1em 0em 2em 0em;
  text-align: justify;
  /* transition: box-shadow 0.3s ease; */
  height: 38em;
}

/* .wrapper:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  } */

.wrapper-2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  align-items: center;
  /* justify-content: center; */
  /* border: solid 3px orange; */
  margin: 0 0 2em 0;
  text-align: center;
}
.wrapper-2 img {
  width: 8em;
  height: 8em;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 0em;
  justify-content: center;
  /* border: solid 3px orange; */
}

.wrapper-2 h5{
  color: #4f4c4c;
  text-align: center;
}

.wrapper-3 {
  display: flex;
  /* flex: 1; */
  margin: 0 0 0 0;
  align-items: flex-start;
  /* justify-content: center; */
  /* margin: 15px; */
  /* border: solid 3px orange; */
  /* vertical-align:text-top; */
}
.text-dec {
  font-size: 3em;
  color: gray;
}


@media (max-width: 1250px) {
  .wrapper{
    height: 43em;
  }
}

@media (max-width: 1150px) {
  .wrapper{
    height: 48em;
  }
}

@media (max-width: 970px) {
  .wrapper{
    height: 53em;
  }
}

@media (max-width: 900px) {
  .testimonials{
    padding: 0 0em 3em 0em;
  }
  
  .wrapper {
    margin: 1em 5em 2em 5em;
  }

  .wrapper-3{
    text-align: justify;
  }
}

@media (max-width: 600px) {
  .wrapper {
    margin: 0 2em 2em 2em;
    height: 43em;
  }

  .eventHeading{
    font-size: 2em;
  }

  .wrapper-3{
    font-size: 0.8em;
  }
}

@media (max-width: 400px) {
  .wrapper {
    margin: 0 1em 2em 1em;
  }
}
