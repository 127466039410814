/* css for member panel */

.member-panel {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0px 50px;
}

.member-panel-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  margin-bottom: 50px;
}

.co-team {
  margin-top: 20px;
}

.member-panel-details {
  width: 85%;
  word-wrap: break-word;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  text-align: justify;
  line-height: 36px;
  color: #595d6b;
  margin: 20px 0px 0px 0px;
}

.member-team h2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
  color: #242731;
}

.member-team {
  margin-top: 25px;
}

.mtabs {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.mtab {
  flex-grow: 1;
  margin: 1rem 1rem 2rem 1rem;
  padding: 0.6em 3em;
  font-size: 1em;
  font-weight: 700;
  border: none;
  border-radius: 1em;
  color: rgb(48, 42, 42);
  background-color: #cfcfcf;
  transition: flex-grow 0.5s ease;
}

#active {
  color: white;
  background-color: #ff845e;
  flex-grow: 4;
}

.member-panel-heading {
  margin-top: 3rem;
}

.end-para {
  margin-bottom: 100px;
}

@media screen and (max-width: 900px) {
  .member-panel {
    margin: 0px 20px;
  }

  .member-panel-details {
    font-size: 18px;
    line-height: 27px;
    margin: 10px 0px;
  }

  .end-para {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 600px) {
  .member-panel {
    margin: 0px 10px;
  }

  .member-panel-details {
    font-size: 16px;
    line-height: 24px;
  }

  .mtab{
    height: 130%;
    padding: 0.6em 1em;
  }

  /* .member-panel-cards{
        margin-bottom: 10px;
    } */
}
