/* .Imain{
  background: #fff7f4;
} */

.Iheading {
  margin: 1em 0 0 0;
  font-size: 3rem;
  font-weight: 700;
}

.Icards-Row1 {
  display: flex;
  flex-direction: row;
  margin: 1em 2em 1em 2em;
}

.Icards-Row2 {
  display: flex;
  flex-direction: row;
  margin: 1em 2em 17em 2em;
}

.Icard {
  flex: 1;
  background: #fff7f4;
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  text-align: justify;
  padding: 1em;
  margin: 1em;
  cursor: pointer;
  transition: transform 0.3s;
}

.Icard:hover{
  background: #ffe0e0;
  transform: scale(0.95);
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
}

/* .ICCImag{
  display: block;
  width: 100%;
  height: 85vh;
  filter: brightness(40%);
  object-fit: cover;
  z-index: 0;
} */

/* .Icard2 {
  text-align: center;
} */

.IcardHead {
  font-size: 2rem;
  font-weight: 700;
  color: #000000;
  text-align: center;
  padding: 0 0 0.3em 0;
  /* border-bottom: 2px solid #000000; */
  z-index: 1;
  /* transition: transform 0.3s; */
}

/* .Icard:hover .IcardHead,
.Icard:hover .IcardContent{
  color: #ffffff;
} */

.IcardContent {
  font-size: 1.2em;
  font-weight: 200;
  color: #000000;
  margin: 0.8em 1em 0 1em;
  z-index: 1;
  /* transition: transform 1s; */
}

@media (max-width: 1250px) {
  .Icards-Row1 {
    flex-direction: column;
    margin: 1em 2em 1em 2em;
  }

  .Icards-Row2 {
    flex-direction: column;
    margin: 1em 2em 2em 2em;
  }
  .Icard {
    flex-grow: 1;
    margin: 1em 0 1em 0;
  }
  /* .Icard2Content {
    margin: 3rem 0;
  } */
}

@media (max-width: 650px) {
  /* .Imain{
    font-size: 70%;
  } */

  .Iheading{
    font-size: 2rem;
  }

  .IcardHead {
    font-size: 1.5rem;
  }

  .IcardContent {
    font-size: 1rem;
  }
}