.card {
  position: relative;
  width: 418px;
  height: auto;
  min-height: 443px;

  box-shadow: 0px 4px 26px rgba(186, 186, 186, 0.35);
  border-radius: 30px;
  max-width: 95vw;
  overflow: hidden;
}

.text {
  height: 112px;
  width: 210px;
  position: absolute;
  right: 13.88%;
  top: 31.03%;
}

.ig-id {
  position: absolute;
  right: 50%;
  top: 6.9%;
  transform: translateX(50%);

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;

  color: #e0dbff;
}

/* .card::after {
  background: rgba(36, 39, 49, 0.44);
  filter: blur(17px);
  border-radius: 0px 0px 30px 30px;
} */

.card-front {
  height: 100%;
  min-height: 443px;
  background: #8574eb;
  position: relative;
  border-radius: 30px;
}

.card-front>img {
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.card-details {
  height: 100%;
  position: absolute;
}

.container {
  align-items: flex-start;
  /* height: 112px; */
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}

h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 45px;
  color: #242731;
  font-family: "Poppins";
}

span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #ffffff;
}

.speaker {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px 0;
  background: linear-gradient(rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.8));
  /* filter: blur(17px); */
  border-radius: 0px 0px 30px 30px;
  box-sizing: border-box;
}

.speaker>span {
  margin-left: 50px;
}

span.name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  filter: none;
}

span.company {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.card-details {
  margin: 0 20px;
}

.card-details>p {
  text-align: left;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #595d6b;
}

.det-container {
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 28px;
}

.det-container>span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 28px;
  color: #595d6b;
}

.register,
.register-closed {
  background-color: var(--primary-orange) !important;
  border-color: var(--primary-orange) !important;
  margin: auto;
  font-size: 17px;
  font-family: "Poppins";
  font-weight: 400;
  padding: 10px 20px;
  border-radius: 50px;
}

.register,
.register-closed {
  position: absolute;
  bottom: 20px;
  right: 50%;
  transform: translateX(50%);
}

.register:hover {
  background-color: var(--parimary-white) !important;
  color: #000;
  border-color: var(--primary-orange);
}

.register-closed {
  background-color: var(--parimary-white) !important;
}

.register-closed {
  width: 80%;
}

.yt {
  width: 100%;
  padding: 0 0 40px 0;
}

p.desc {
  max-height: 120px;
  overflow-y: scroll;
}

p.desc::-webkit-scrollbar {
  display: none;
}

p.desc {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.shown-in-mobile {
  display: none;
}

.hidden-in-mobile {
  display: block;
}

.mobile-button {
  margin: 0 auto;
}

@media (max-width: 425px) {
  h2.mt-4 {
    margin-top: 12px !important;
  }

  .det-container>span {
    font-size: 15px;
    font-weight: 600;
  }

  .det-container {
    margin-bottom: 10px;
  }

  .register-closed {
    width: 90%;
    height: 30px !important;
    padding: 2px 0 !important;
  }

  .yt {
    height: 100%;
    width: auto;
    padding: 0 0 0 0;
  }

  p.desc {
    max-height: 100px;
    overflow-y: scroll;
  }

  .register,
  .register-closed {
    bottom: 15px;
  }

  .shown-in-mobile {
    display: block;
    position: relative;
    top: 0px;
    margin: 10px auto;
  }

  .hidden-in-mobile {
    display: none;
  }

  .mobile-button {
    display: table;
    margin: 0 auto;
  }

}

@media (max-width: 375px) {
  .text {
    right: 5%;
    top: 25%;
  }

  h2 {
    font-weight: 600;
    font-size: 25px;
  }

  p.desc {
    max-height: 100px;
    overflow-y: scroll;
    margin: 0 !important;
  }

  .register,
  .register-closed {
    bottom: 10px;
    padding: 5px 20px;
  }
}

@media (max-width: 320px) {
  .text {
    right: 50%;
    transform: translateX(50%);
  }

  /* img.yt {
    bottom: -20%;
  } */
  .card-front {
    overflow: hidden;
  }

  h2.mt-4 {
    margin-top: 8px !important;
    font-size: 20px;
    line-height: 30px;
  }

  p.desc {
    max-height: 80px;
    overflow-y: scroll;
    margin: 0 !important;
  }
}


/* =========== CARD FLIP ===========  */
.card-front,
.card-rear {
  -webkit-backface-visibility: hidden !important;
  backface-visibility: hidden !important;
  transform-style: preserve-3d;
  transition: ease-in-out 600ms;
}


.card-rear {
  transform: rotateY(-180deg) !important;
  /* visibility: hidden; */
}

.card-front {
  transform: rotateY(0deg) !important;
  /* visibility: hidden; */
}

.flip-front {
  transform: rotateY(180deg) !important;
}

.flip-rear {
  transform: rotateY(0deg) !important;
}

@media(min-width: 769px) {
  .card:hover .card-front {
    transform: rotateY(180deg) !important;
  }

  .card:hover .card-rear {
    transform: rotateY(0deg) !important;
    /* visibility: visible; */
  }
}