@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  box-sizing: border-box !important;
  -webkit-tap-highlight-color: transparent;
}
body {
  margin: 20;
  font-family: Poppins;
  overflow: hidden;
}

html {
  overflow-x: hidden;
}

a {
  text-decoration: none !important;
}
