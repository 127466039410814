.event-form{
  height: 100vh;
}

/* .event-form {
  min-height: 80vh;
  width: 80vw;
  max-width: 500px;
  margin: 3rem auto;
  text-align: left;
}

form {
  gap: 30px;
  width: 100%;
  min-width: 300px;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: rgba(255, 121, 80, 0.948);
  --bs-btn-border-color: rgb(255, 109, 65);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(255, 121, 80);
  --bs-btn-hover-border-color: rgb(255, 107, 62);
  --bs-btn-focus-shadowrgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(255, 121, 80);
  --bs-btn-active-border-color: rgb(255, 121, 80);
  --bs-btn-active-shadow: inset 0 3px 5pxrgba (0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: rgb(253, 150, 118);
  --bs-btn-disabled-border-color: rgb(246, 140, 107);
}

.form-title {
  width: 100%;
  height: auto;
  color: white;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 20px 0;
  position: relative;
  background: linear-gradient(
    45deg,
    rgba(255, 130, 92, 1) 20%,
    rgba(220, 131, 103, 1) 60%
  );
}
*/
.white {
  color: #fff !important; 
  background: linear-gradient(
    45deg,
    rgba(150,150,250,1) 20%,
    rgba(200,200,200,1) 60%,
  );
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.7) 20%,
    rgba(0, 0, 0, 0.6) 50%  /*rgba(0, 0, 0, 1) 90% */
   );
}

.details a {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
}
.details a:hover {
  text-decoration: underline !important;
}
/*
.form-title > span.heading {
  font-weight: 600;
  font-size: 26px;
  margin: 10px 0 !important;
  color: inherit;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.form-title > img {
  position: absolute;
  height: 80%;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.form-title .details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  color: inherit;
  justify-content: left;
}
*/
.form-title span {
  color: inherit;
  display: flex;
  align-items: center;
  gap: 5px;
}
/*
.MuiCollapse-root {
  position: fixed;
  bottom: 0;
  z-index: 9999;
  width: 90vw;
  max-width: 600px;
  margin: 2rem 0;
  left: 50%;
  transform: translateX(-50%);
}
.css-18djxyj-MuiPaper-root-MuiAlert-root {
  font-size: 1rem !important;
  font-weight: 600 !important;
  align-items: center;
}
.MuiAlert-message {
  font-size: 1rem !important;
  font-weight: 600 !important;
}
*/
.show {
  visibility: visible !important;
}
.group-left {
  text-align: left !important;
  justify-content: left !important;
  align-items: flex-start !important;
}

@media (max-width: 425px) {
  .group-img {
    height: 60% !important;
    position: absolute !important;
    right: 5px !important;
    top: 60% !important;
  }
  .form-title > span.heading {
    text-align: center;
  }
  .form-title > img {
    visibility: hidden;
  }

  .event-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .form-title {
    align-items: center;
  }
} 
