
footer {
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.f-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.logo {
  flex: 2;
  display: flex;
}

.company,
.other-links {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;
}

.company{
  flex: 1;
  margin: 0;
  padding: 0;
}

.first_row, .second_row{
  display: flex;
  flex-direction: column;
}

.f-logo {
  width: 200px;
  scale: 0.8;
}

.f-container {
  max-width: 1350px;
  width: 90vw;
  margin: 0 auto;
  padding: 0rem;
  box-sizing: border-box;
}

span > u {
  color: inherit;
  font-size: 20px;
}
.f_links {
  text-decoration: none;
  color: white;
  margin-top: 5px;
  font-size: 19px;
  white-space: nowrap;
}

.f_links:hover {
  color: white;
}

.copyright{
    margin: 0 0 1em 0;
}

.social-media {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0px !important;
  /* padding-left: 1rem !important; */
}

.social-media-item {
  margin: 10px !important;
}

@media (max-width: 800px) {
  footer {
    margin-top: 50px !important;
  }
  .f-top {
    flex-direction: column;
    gap: 1rem;
  }

  .logo,
  .company,
  .other-links {
    width: 100%;
    margin-top: 0px;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  .company{
    flex-direction: column;
  }

  .f_links{
    padding: 0 1em;
    margin: 0 1em;
  }

  .f-container {
    max-width: 1250px;
    width: 100vw;
    margin: 0 auto;
    padding: 30px 30px 0 30px;
    box-sizing: border-box;
  }

  .copyright {
    margin: 30px auto;
  }
}

@media (max-width: 510px) {
  .first_row, .second_row{
    flex-direction: column;
  }
}

@media (max-width: 320px) {
  a > svg {
    height: 1.5rem;
    width: 1.5rem;
  }

  .copyright {
    margin: 30px auto;
  }
}
