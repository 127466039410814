.aw-main {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 1rem;
  align-items: center;
}

.aw-heading {
  font-size: 3rem;
  font-weight: bold;
}

.aw-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 2rem 0;
  gap: 2rem;
  color: var(--primary-grey-blue) !important;
}

.aw-awardimg {
 border-radius: 10%;
 overflow: hidden;
 margin: 1rem 0.5rem;
}

.aw-text {
  max-width: 45vw;
  font-size: 1.5rem;
  vertical-align: middle;
  text-align: left;
}

.aw-img{
  height: auto;
  width:min(45vw,70vh);
  object-fit: contain;
}
.aw-awardvid{
  height: auto;
  width:100vw;
  object-fit: contain;
  background-color: rgb(255, 255, 255);
}

@media (max-width: 768px) {
  .aw-heading {
    font-size: 2.5rem;
  }
  .aw-content {
    flex-direction: column-reverse;
    align-items: center;
    gap: 1rem;
    
  }
  .aw-text {
    padding: 0 1rem;
    font-size: 1rem;
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .aw-heading {
    font-size: 2rem;
  }
  .aw-content {
    flex-direction: column-reverse;
    align-items: center;
    gap: 1rem;
    
  }
  .aw-text {
    padding: 0 1rem;
    font-size: 1rem;
    max-width: 100%;
  }
}

