.round-border{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.reel-img {
  object-fit: cover;
}
.carousel-inner {
  width: 100% !important;
  height: 100%;
  border-radius: 40px;
}
.carousel {
  width: 502px;
  height: 502px;
}
.slide {
  width: 502px !important;
}
.carousel-item {
  width: 100%;
  height: 100%;
}
.carousel-img {
  width: 100% !important;
  height: 100% !important;
  object-fit: fill;
}

@media (max-width: 650px) {
  .carousel {
    width: calc(90vw - 48px) !important;
    height: calc(90vw - 48px) !important;
  }

  .arc,.dashes{
    display: none;
  }
}

@media (max-width: 425px) {
  /* .carousel {
    width: 400px !important;
    height: 400px !important;

    margin-top: 20px;
    margin-left: 5px;
  } */
}
@media (max-width: 375px) {
  /* .carousel {
    width: 270px !important;
    height: 270px !important;

    margin-left: 45px;
    margin-top: 20px;
  } */
}
@media (max-width: 320px) {
  /* .carousel {
    width: 270px !important;
    height: 270px !important;

    margin-left: 20px;
    margin-top: 20px;
  } */
}
