/* css for about us page */

.about-us {
  font-family: Poppins;
  text-align: justify;
}

.about-content > h1 {
  font-weight: 1000;
  font-size: 36px;
  line-height: 54px;
}

.about-content > p {
  color: var(--primary-grey-blue) !important;
  line-height: 33px;
  font-size: 22px;
  font-weight: 400;
}

.about-img {
  width: 45%;
  max-width: 90vw;
  height: auto;
}

@media (max-width: 425px) {
  .about-us {
    max-width: 85vw;
  }
}
