.loader {
  font-size: 16px;
  font-size: 1rem;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.ld-container {
  font-size: 1rem;
  min-height: 6rem;
  border: none;
  border-radius: 6px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.qt-loading {
  position: relative;
  width: 8em;
  height: 3em;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
}

#loader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#loader > div {
  position: relative;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  background-color: rgb(255, 133, 95);
  animation: loader 2s infinite linear;
}
#loader > div:nth-child(2) {
  animation-delay: 0.3s;
}
#loader > div:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes loader {
  0% {
    transform: translateY(0%);
  }
  20% {
    transform: translateY(-50%);
  }
  40% {
    transform: translateY(0%);
  }
}
