.careers-title {
  margin: 20px;
}

.careers-panel {
  max-width: 1350px;
  margin: 30px auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.careers-panel-cards {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

@media (max-width: 1400px) {
  .careers-panel {
    max-width: 90vw;
  }
}
/* ================================= */
.careers-card {
  max-width: 500px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  -moz-box-shadow: 0 0 3px #ccc;
  -webkit-box-shadow: 0 0 3px #ccc;
  box-shadow: 0 0 3px #ccc;
}

.img {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.img img {
  width: 100%;
  height: auto;
  object-fit: fill;
}

.content {
  text-align: left !important;
  padding: 10px;
}

.p-content{
  margin-top: 15px;
  max-height:200px;
  overflow-x: hidden !important;
  overflow-wrap: break-word !important;
  overflow-y: scroll;
  min-height: 250px;

}

.p-content::-webkit-scrollbar {
  display: none;
}

.p-content {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

span.type {
  color: black;
  font-weight: 200 !important;
}
.apply-btn {
  border: none;
  outline: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-orange);
  padding: 7px 0;
}

.apply-btn:disabled{
  opacity: 0.7;
}
.apply {
  text-decoration: none;
  color: var(--primary-white);
  font-weight: bold;
  font-size: 18px;
  width: 100%;
}

.apply:hover {
  color: var(--primary-white);
}

@media (max-width: 600px) {
  .careers-card {
    max-width: 80vw;
  }
}

@media (max-width: 425px) {
  .p-content{
    max-height:150px;
  }
}

@media (max-width: 425px) {
  .p-content{
    max-width:196px;
  }
}
