.cardPanel {
  position: relative;
  height: auto;
  margin: auto;
  margin-top: 30px !important;
  /* top: 1506px; */
  /* overflow-x: scroll;
  overflow: hidden; */
  
  /* grid-template-columns: repeat(7, 420px);
  grid-gap: 1rem; */
}

.event-caution {
  width: fit-content;
  min-width: 185px;
  position: absolute;
  top:55px;
  right:50%;
  transform: translateX(50%);
  color: red;
  font-size: 14px;
  font-style: italic;
  display: none;
}

.upcoming,.closed{

  padding: 80px 60px 60px 60px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center ;
  gap: 64px;
}

.upcoming{
  /* display: none; */
}

.top-0{
  top: 10px !important;
}

@media (max-width: 768px) {
  .event-caution {
    display: block;
  }
}

@media(max-width:425px){
  .upcoming,.closed{
    padding: 20px;
    padding-top: 80px;
  }

  .event-caution {
    width: fit-content;
    position: absolute;
    top:55px;
    right:50%;
    transform: translateX(50%);
    color: red;
    font-size: 12px;
    font-style: italic;
  }
}
