/* .textBlock_intro{
    background: #FFFFFF;
    position: absolute;
    top:90px;
    text-align: left;
    height: 353px;
    padding: 66px;
    font-family: 'Poppins';
}
.p1{
    font-size: 58px;
    line-height: 87px;
    width: 674px;
}
.p2{
    font-size: 52px;
    line-height: 65px;
    width: 674px;
    color: #FF845E;
}
.p3{
    font-size: 20px;
    line-height: 30px;
    width: 674px;
    color: #595D6B;
}
.btn.btn-outlined{
    height: 51px;
    width:147px;
    position: absolute;
    border-radius: 50px;
    border-color: #FF845E;
    font-size: 20px;
    color:#595D6B;
   
    
}
.showReel{
    height: 592px;
    width: 592px;
    padding: 20px;
    border-radius: 50%;
    object-fit:cover;
    position: absolute;
    left:1110px;
    top: 74px;
    scale: 10%;
    z-index: 1;
    user-select: none;
}
.arc{
    position: absolute;
    height: 592px;
    left:1110px;
    top: 75px;
    z-index: 0;
    user-select: none;
}
.left{
    position: absolute;
    top: 350px;
    left: 1050px;
    user-select: none;
}
.left:hover{
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100%;
}
.right:hover{
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100%;
}
.right{
    position: absolute;
    top: 350px;
    left: 1725px;
    user-select: none;
} */

/* 
:root {
    --primary-white: #fff;
    --primary-orange: #FF845E;
    --primary-grey-blue: #595d6b;
} */

.column1 {
  max-width: 650px !important;
  padding: 12px;
}

.hero {
  max-width: 1350px;
  margin: 30px auto;
}

.row {
  max-width: 1350px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.w-auto {
  width: auto !important;
}

.brand_text {
  font-family: "Poppins";
  color: var(--primary-orange) !important;
}

.text_info {
  color: var(--primary-grey-blue) !important;
}

.btn_readmore {
  position: relative;
  margin: auto;
  left: 0;
  border-radius: 50px !important;
  background-color: var(--primary-white) !important;
  border-color: var(--primary-orange) !important;
  color: var(--primary-grey-blue) !important;
}

#btn_ca {
  text-decoration: none;
  text-align: left;
}

.btn_ca {
  position: relative;
  display: flex !important;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 50px !important;
  background-color: var(--primary-orange) !important;
  border-color: var(--primary-black) !important;
  color: var(--primary-white) !important;
}

.text_info,
.btn_readmore,
.btn_ca {
  margin-top: 30px;
}

.btn_readmore:hover {
  background-color: var(--primary-orange) !important;
  border-color: var(--primary-orange) !important;
  color: var(--primary-white) !important;
}

.btn_ca:hover {
  background-color: var(--primary-white) !important;
  border-color: var(--primary-orange) !important;
  color: var(--primary-grey-blue) !important;
}

.vertical-center {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.img_reel {
  height: 25rem;
  width: 30rem;
}

.readmore_ca {
  position: relative;
  display: flex;
  justify-content: left;
  gap: 10px;
}

.readmore_ca a {
  min-width: 120px;
  text-align: left;
}

.left {
  display: flex;
  align-items: center;
  justify-content: left;
}

.right {
  display: flex;
  align-items: center;
  justify-content: right;
}

@media (max-width: 1350px) {
  .hero {
    max-width: 90vw;
  }
}

@media (max-width: 1250px) {
  /* .carousel-container{
    display: none;
  } */
}

@media (max-width: 991px) {
  .carousel-container {
    margin-top: 60px !important;
  }
}

@media (max-width: 950px) {
  .hero {
    max-width: 90vw;
  }

  .row {
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
    margin: 20px 0 !important;
  }
}

@media (max-width: 650px) {
  .row {
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
    max-width: 90vw !important;
  }

  .column1 {
    /* margin: 30px 0 0 0 ; */
    max-width: 90vw !important;
  }

  /* .corousal-slide {
    height: 100%;
    width: 100%;
  } */

  .carousel-container {
    width: 90vw;
    height: 90vw;
    margin-top: 80px !important;
  }
}

@media (max-width: 390px) {

  .carousel,
  .slide {
    margin: 0 !important;
  }

  .row {
    max-width: 100vw !important;
    margin: 0 !important;
  }

  .column1 {
    padding: 0;
    margin: 0 !important;
  }
}

@media (max-width: 425px) {
  .readmore_ca {
    flex-direction: column;
  }

  .btn_ca {
    margin-top: 10px;
  }

  .buttons {
    width: 100%;
    display: flex;
  }
}



/* ======================================= */

.carousel-container {
  position: relative;
  /* padding: 0px !important; */
  max-width: 100vw;
  box-sizing: border-box !important;
  padding: 12px !important;
}

.arc {
  box-sizing: border-box;

  position: absolute;
  width: 100%;
  height: 100%;

  border: 6px solid rgba(89, 93, 107, 0.6);
  border-radius: 50%;
  margin: 0;

  clip-path: circle(50% at 255px);

  /* background-color: grey; */
}

.dashes {
  box-sizing: border-box;

  /* background-color: red; */

  position: absolute;
  width: 100%;
  height: 100%;

  border: 4px dashed #ff845e;
  border-radius: 50%;
  margin: 0;
  clip-path: circle(50% at 500px);
}

/* ======================================== */