.weeklycontent
{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.WCmain {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  width: 100%;
}

.WCheading {
  font-size: 3rem;
  font-weight: 700;
}

.WCrow {
  display: flex;
  flex-wrap: wrap;
  margin: 0rem 0rem 2rem 0rem;
  justify-content: center;
  /* gap: 6rem; */
}

.WCcard {
  overflow: hidden;
  position: relative;
  margin: 5rem 1rem 0rem 1rem;
  /* border: 2px solid; */
  /* border-color: #ff845e; */
  border-radius: 0.5rem;
  box-shadow: 2px 2px 8px rgba(68, 67, 67,0.5);
  height: max-content;
  width: 24rem;
  /* background:linear-gradient(to bottom, transparent 80% ,rgba(255, 132, 94,0.35) ); */
  transition-duration: 0.5s;
}

.WCnot-card {
  margin: 5rem 1rem;
  height: 24rem;
  width: 24rem;
  /* visibility: hidden; */
  display: none;
}

.WCweek_1{
  width: 100%;
  height: auto;
}

.WCweek-img{
  object-fit: cover;
}

.WCcard:hover{
  transform: scale(1.05);
}

.WCweek-text{
  position: absolute;
  font-weight: 500;
  color: rgb(106, 106, 106);
  bottom: 8px;
  left: 16px;
  font-size: 1.5rem;
}

@media (max-width: 1315px) {
  .WCcard{
    max-height: 30rem;
    width: 25rem;
  }
  .WCnot-card{
    display: none;
  }
}

@media (max-width: 950px) {
    .WCcard{
      width: 24rem;
      margin: 3rem 0.5rem 0rem 0.5rem;
    }
    .WCnot-card{
      display: none;
    }
    .WCmain {
      margin: 0;
      padding: 0.5rem;
    }
}
