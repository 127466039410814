/* .community_why{
position: relative;
height: 550px;
width: 100%;
min-width: 1905px;
top: 802px;
background: #FFF7F4;
font-family: 'Poppins';
font-size: 22px;
}

.ball{
    width: 70px;
    height: 70px;
}

.b1{
    position: absolute;
    left: 90px;
    top:139px;
}
.b2{
    position: absolute;
    left: 90px;
    top:311px;
}
.b3{
    position: absolute;
    right: 90px;
    top: 225px;
}
.b4{
    position: absolute;
    right: 90px;
    top:397px;
}
.text_left{
    color: #595D6B;
    position: absolute;
    top:30%;
    padding-left: 100px;
    padding-right: 100px;
    width: 1000px;
    text-align: left;
}
.text_right{
    color: #595D6B;
    position: absolute;
    top:30%;
    padding-left: 100px;
    padding-right: 100px;
    right: 30px;
    width: 10000px;
    text-align: end;
}       
.title{
    position: relative;
    top: 40px;
    color: #242731;
    font-size: 36px;
    font-weight: 200;
} */

/* ----------------------------------------------------------------------*/

.illust1{
  position: absolute;
  left: 50px;
  top: -250px;
  width: 250px;
  height: 250px;
}

.community_why {
  margin-top: 100px !important;
  margin: 30px 0;
  background-color: #fff7f4;
  max-width: 100vw;
  position: relative;
}

.header {
  color: #242731;
  font-size: 2rem;
  font-weight: 200;

}

.point{
  margin-top: 30px;
}

.ball {
  width: 45px;
}

/* .txt_1 {
  vertical-align: middle;
} */

@media(max-width:1350px){
  .illust1{
    left: 0;
  }
}

@media(max-width:1250px){
  .illust1{
    display: none;
  }
}

@media (max-width: 425px) {
  .ball {
    width: 40px;
  }
}
@media (max-width: 425px) {

  .point{
    margin-top: 10px;
  }

  .ball {
    width: 35px !important;
    height: auto;
  }

  .dot {
    height: 100%;
  }
}
